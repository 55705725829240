import Vue from 'vue';
import BootstrapVue, { NavPlugin } from 'bootstrap-vue';
export class NavVueComponent extends Vue {
    isActive(viewLocations) {
        const currentPath = window.location.pathname.toLowerCase();
        let isActiveMatch = false;
        viewLocations.forEach(path => {
            path = path.toLowerCase();
            isActiveMatch = path == currentPath;
            if (isActiveMatch) {
                return;
            }
        });
        return isActiveMatch;
    }
    ;
    getBoundMethods() {
        return {
            isActive: this.isActive
        };
    }
}
const faqVueComponent = new NavVueComponent();
Vue.use(BootstrapVue);
Vue.use(NavPlugin);
new Vue({
    el: "#app-nav",
    methods: faqVueComponent.getBoundMethods()
});
